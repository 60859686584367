<template>
  <div class="text-center">
    <v-menu
      v-if="activeLayers"
      v-model="opacityMenu"
      :close-on-content-click="false"
      :nudge-width="100"
      dark
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-blur
          min-height="40"
          min-width="40"
          text
          v-bind="attrs"
          :disabled="!activeLayers"
          v-on="on"
        >
          <SvgIcon
            :title="$t('ServiceCard.transparency')"
            :active="activeLayers"
            :path="
              'M16.7131222,7.28687783 C16.7131222,5.72438914 15.4475566,4.45882353 13.8850679,4.45882353 L7.28687783,4.45882353 C5.72438914,4.45882353 4.45882353,5.72438914 4.45882353,7.28687783 L4.45882353,13.8859729 L4.45882353,13.8850889 C4.45882353,15.4475776 5.72438914,16.7131432 7.28687783,16.7131432 C7.28687783,18.2756319 8.55244344,19.5411975 10.1149321,19.5411975 L16.7140271,19.5411975 L16.7131432,19.5411975 C18.2756319,19.5411975 19.5411975,18.2756319 19.5411975,16.7131432 L19.5411975,10.1149532 C19.5411975,8.55246448 18.2756319,7.28689887 16.7131432,7.28689887 L16.7131222,7.28687783 Z M11.7233032,15.7710407 L15.7710407,11.7233032 L15.7710407,13.2186425 L13.2187783,15.770905 L11.7233032,15.7710407 Z M8.89524887,15.7710407 L15.7710407,8.89524887 L15.7710407,10.3905882 L10.390724,15.770905 L8.89524887,15.7710407 Z M12.2764706,8.22895928 L8.22873303,12.2766968 L8.22873303,10.7813575 L10.7809955,8.22909502 L12.2764706,8.22895928 Z M15.1045249,8.22895928 L8.22873303,15.1047511 L8.22873303,13.6094118 L13.6090498,8.22909502 L15.1045249,8.22895928 Z M8.47443439,9.2028733 C8.64500226,8.89708145 8.89687783,8.64522624 9.20176471,8.47463801 L8.47443439,9.2028733 Z M15.5242081,14.7978959 L15.5250919,14.7978959 C15.354524,15.1028054 15.1026484,15.3546606 14.7977615,15.5252262 L15.5242081,14.7978959 Z M18.5988914,16.713009 C18.5971011,17.754095 17.7539819,18.5971946 16.7129186,18.5989593 L10.1147285,18.5989593 C9.07364253,18.5971916 8.23054299,17.7540724 8.22877828,16.713009 L13.8848869,16.713009 C15.4473756,16.713009 16.7129412,15.4474434 16.7129412,13.8849548 L16.7129412,8.22884615 C17.7540271,8.2306138 18.5971267,9.07373303 18.5988914,10.1147964 L18.5988914,16.713009 Z'
            "
          />
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t('ServiceCard.transparency') }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <SvgIcon
                :active="false"
                :title="$t('ServiceCard.transparency')"
                :path="
                  'M16.7131222,7.28687783 C16.7131222,5.72438914 15.4475566,4.45882353 13.8850679,4.45882353 L7.28687783,4.45882353 C5.72438914,4.45882353 4.45882353,5.72438914 4.45882353,7.28687783 L4.45882353,13.8859729 L4.45882353,13.8850889 C4.45882353,15.4475776 5.72438914,16.7131432 7.28687783,16.7131432 C7.28687783,18.2756319 8.55244344,19.5411975 10.1149321,19.5411975 L16.7140271,19.5411975 L16.7131432,19.5411975 C18.2756319,19.5411975 19.5411975,18.2756319 19.5411975,16.7131432 L19.5411975,10.1149532 C19.5411975,8.55246448 18.2756319,7.28689887 16.7131432,7.28689887 L16.7131222,7.28687783 Z M11.7233032,15.7710407 L15.7710407,11.7233032 L15.7710407,13.2186425 L13.2187783,15.770905 L11.7233032,15.7710407 Z M8.89524887,15.7710407 L15.7710407,8.89524887 L15.7710407,10.3905882 L10.390724,15.770905 L8.89524887,15.7710407 Z M12.2764706,8.22895928 L8.22873303,12.2766968 L8.22873303,10.7813575 L10.7809955,8.22909502 L12.2764706,8.22895928 Z M15.1045249,8.22895928 L8.22873303,15.1047511 L8.22873303,13.6094118 L13.6090498,8.22909502 L15.1045249,8.22895928 Z M8.47443439,9.2028733 C8.64500226,8.89708145 8.89687783,8.64522624 9.20176471,8.47463801 L8.47443439,9.2028733 Z M15.5242081,14.7978959 L15.5250919,14.7978959 C15.354524,15.1028054 15.1026484,15.3546606 14.7977615,15.5252262 L15.5242081,14.7978959 Z M18.5988914,16.713009 C18.5971011,17.754095 17.7539819,18.5971946 16.7129186,18.5989593 L10.1147285,18.5989593 C9.07364253,18.5971916 8.23054299,17.7540724 8.22877828,16.713009 L13.8848869,16.713009 C15.4473756,16.713009 16.7129412,15.4474434 16.7129412,13.8849548 L16.7129412,8.22884615 C17.7540271,8.2306138 18.5971267,9.07373303 18.5988914,10.1147964 L18.5988914,16.713009 Z'
                "
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-slider
          v-model="sliderOpacityValue"
          class="my-0 mx-2"
          hide-details
          color="green"
          track-color="green"
          thumb-label
          max="100"
          min="0"
          @input="setLayerOpacity()"
          @mouseup="opacityMenu = false;"
        >
          <template v-slot:thumb-label="{ value }">
            {{ value + "%" }}
          </template>
        </v-slider>

        <div class="sliderLabel mx-3">
          <p>0</p><p>100</p>
        </div>
      </v-card>
    </v-menu>

    <v-btn
      v-else
      min-height="40"
      min-width="40"
      text
      :disabled="!activeLayers"
    >
      <SvgIcon
        :title="$t('ServiceCard.transparency')"
        :active="activeLayers"
        :path="
          'M16.7131222,7.28687783 C16.7131222,5.72438914 15.4475566,4.45882353 13.8850679,4.45882353 L7.28687783,4.45882353 C5.72438914,4.45882353 4.45882353,5.72438914 4.45882353,7.28687783 L4.45882353,13.8859729 L4.45882353,13.8850889 C4.45882353,15.4475776 5.72438914,16.7131432 7.28687783,16.7131432 C7.28687783,18.2756319 8.55244344,19.5411975 10.1149321,19.5411975 L16.7140271,19.5411975 L16.7131432,19.5411975 C18.2756319,19.5411975 19.5411975,18.2756319 19.5411975,16.7131432 L19.5411975,10.1149532 C19.5411975,8.55246448 18.2756319,7.28689887 16.7131432,7.28689887 L16.7131222,7.28687783 Z M11.7233032,15.7710407 L15.7710407,11.7233032 L15.7710407,13.2186425 L13.2187783,15.770905 L11.7233032,15.7710407 Z M8.89524887,15.7710407 L15.7710407,8.89524887 L15.7710407,10.3905882 L10.390724,15.770905 L8.89524887,15.7710407 Z M12.2764706,8.22895928 L8.22873303,12.2766968 L8.22873303,10.7813575 L10.7809955,8.22909502 L12.2764706,8.22895928 Z M15.1045249,8.22895928 L8.22873303,15.1047511 L8.22873303,13.6094118 L13.6090498,8.22909502 L15.1045249,8.22895928 Z M8.47443439,9.2028733 C8.64500226,8.89708145 8.89687783,8.64522624 9.20176471,8.47463801 L8.47443439,9.2028733 Z M15.5242081,14.7978959 L15.5250919,14.7978959 C15.354524,15.1028054 15.1026484,15.3546606 14.7977615,15.5252262 L15.5242081,14.7978959 Z M18.5988914,16.713009 C18.5971011,17.754095 17.7539819,18.5971946 16.7129186,18.5989593 L10.1147285,18.5989593 C9.07364253,18.5971916 8.23054299,17.7540724 8.22877828,16.713009 L13.8848869,16.713009 C15.4473756,16.713009 16.7129412,15.4474434 16.7129412,13.8849548 L16.7129412,8.22884615 C17.7540271,8.2306138 18.5971267,9.07373303 18.5988914,10.1147964 L18.5988914,16.713009 Z'
        "
      />
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SvgIcon from '@/components/utils/SvgIcon.vue';

export default {
  components: {
    SvgIcon
  },
  props: {
    serviceCardId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      opacityMenu: false,
      sliderOpacityValue: 60
    };
  },
  computed: {
    ...mapState(['map']),
    activeLayers() {
      return this.map.OLmap.getLayers().getArray().some((layer) => layer.values_.service_id === this.serviceCardId);
    }
  },
  watch: {
    $route() {}
  },
  mounted() {
  },
  methods: {
    ...mapActions([]),
    setLayerOpacity() {
      const layers = this.map.OLmap.getLayers().getArray().slice(1);
      layers.forEach(layer => {
        if (layer.values_.service_id == this.serviceCardId) {
          const newValue = this.sliderOpacityValue / 100;
          layer.setOpacity(newValue);
        }
      });
    }
  }
};
</script>

<style scoped>
.sliderLabel {
  display: flex;
  justify-content: space-between;
}
</style>
